import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const CumActioneaza = () => {
  return (
    <div className="w-full ">
      <div className="relative image-container mt-[0px!important]">
        <div className="absolute hidden -translate-x-1/2 lg:block left-1/2 bottom-5">
          <Icon
            icon="iconoir:mouse-scroll-wheel"
            className="text-4xl text-main-400"
          />
        </div>

        <img
          src="/images/cum-actioneaza.png"
          alt="Fumatul"
          className="hidden lg:block"
        />
        <img
          src="/images/cum-actioneaza-mobile.png"
          alt="Fumatul"
          className="block lg:hidden"
        />
      </div>
      <div className="w-full px-8">
        <div className="container pt-20 mx-auto text-center">
          <div className="mx-auto space-y-4 ">
            <h1 className="h1">
              E mai ușor să lupți cu un inamic pe care îl cunoști.
            </h1>
            <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
              <p className="text-base text-center 2xl:text-xl ">
                Căile respiratorii sunt căptușite de o mucoasă. Aici își au
                originea majoritatea cancerelor bronho-pulmonare. În diferite
                contexte, alimentate de factori de risc, celulele mucoase
                degenerează, adică suferă o modificare malignă, în care
                procesele naturale nu mai controlează creșterea acestor celule,
                astfel că ele se pot înmulți aproape necontrolat. Din aceste
                celule tumorale ia naștere o formațiune tumorală. Așadar,
                cancerul bronho-pulmonar reprezintă o afecțiune malignă
                caracterizată printr-o multiplicare necontrolată a celulelor
                epiteliale ale țesutului pulmonar, la nivelul unui plămân sau al
                ambilor plămâni, la nivelul bronhiilor sau al alveolelor
                pulmonare.<sup>1</sup>
                <br />
                <br />
                Dacă afecțiunea nu este descoperită și tratată la timp, tumora
                nu rămâne în plămân, ci se extinde în țesutul sănătos din jur și
                în organele învecinate. Dacă aceste celule degenerate ajung în
                circulația sanguină sau limfatică, se pot forma focare tumorale
                în alte regiuni ale corpului, așa-numitele metastaze. Cancerul
                bronho-pulmonar metastazează cel mai adesea în ficat, în creier
                și în oase.<sup>1</sup>
              </p>
            </div>
            <h1 className="pt-10 h1">
              Dimensiunea celulelor tumorale <br />
              determină tipul de cancer.
            </h1>
            <p className="text-xl italic 2xl:text-3xl">
              Există două tipuri principale de cancer pulmonar<sup>1,2</sup>,
              care se deosebesc <br />
              prin agresivitate și posibila evoluție a bolii:
            </p>
          </div>
        </div>
      </div>
      <div className="container pt-20 mx-auto text-center">
        <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
          <div className="flex-row justify-between lg:space-x-3 lg:flex">
            <div className="w-full lg:w-6/12 bg-[#b701aa]  text-white p-4 lg:p-[90px] text-center">
              <p className="text-base 2xl:text-xl max-w-[400px]  mx-auto">
                • Forma cu celule mici a cancerului pulmonar prezintă o creștere
                rapidă și reprezintă circa 15% din totalul cazurilor de cancer
                bronho-pulmonar. Conform denumirii în limba engleză, această
                formă este prescurtată și SCLC („small cell lung cancer“ =
                carcinom pulmonar cu celule mici)
              </p>
            </div>
            <div className="w-full lg:w-6/12 bg-[#a008cf] text-white p-4 lg:p-[90px] lg:px-[40px] text-center">
              <p className="text-base 2xl:text-xl max-w-[560px] mx-auto">
                • Forma fără celule mici are o frecvență de circa 85%. Conform
                denumirii în limba engleză, această formă este prescurtată și
                NSCLC („non-small cell lung cancer“ = carcinom pulmonar fără
                celule mici). În funcție de tipul de celule de la care pornește
                cancerul în interiorul plămânului, tumorile fără celule mici se
                mai împart în carcinoame scuamoase și carcinoame nonscuamoase,
                în rândul cărora se găsesc și adenocarcinoamele și carcinoamele
                pulmonare cu celule mari<sup>1</sup>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-8">
        <div className="container pt-24 mx-auto">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
          <p className="text-xs lg:text-sm">
              <sup>1</sup>Bristol-Myers Squibb. (n.d). Cancerul Pulmonar:
              Diagnostic și Tratament, Un Ghid Pentru Pacienți și Aparținători;
              <br />
              <sup>2</sup>Molina, J., R., Yang, P., Cassivi, S., D., Schild, S.,
              E., Adjei, A., A. (2008). Non-small cell lung cancer:
              epidemiology, risk factors, treatment, and survivorship. In Mayo
              clinic proceedings, Vol. 83(5). 584-594.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CumActioneaza;
