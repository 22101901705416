import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const CeSpuneMedicul = () => {
  return (
    <div className="w-full ">
      <div className="relative image-container mt-[0px!important]">
        <div className="absolute hidden -translate-x-1/2 lg:block left-1/2 bottom-5">
          <Icon
            icon="iconoir:mouse-scroll-wheel"
            className="text-4xl text-main-400"
          />
        </div>
        <img
          src="/images/ce-spune-medicul.png"
          className="hidden lg:block"
          alt="Fumatul"
        />
        <img
          src="/images/ce-spune-medicul-mobile.png"
          className="block lg:hidden"
          alt="Fumatul"
        />
      </div>
      <div className="w-full px-4 lg:px-0">
        <div className="container pt-20 mx-auto text-center">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <h1 className="h1">Ce se întâmplă la medicul de familie</h1>
            <p className="text-base italic 2xl:text-3xl lg:text-xl">
              Mergeți la medic dacă prezentați oricare dintre simptomele
              asociate cancerului bronho-pulmonar. Medicul de familie vă va
              consulta și evalua. În urma consultului, acesta vă poate recomanda
              o serie de analize, precum o radiografie toracică. În funcție de
              rezultatele investigațiilor, veți fi direcționați către medicul
              specialist pentru efectuarea mai multor teste<sup>1</sup>.
            </p>
            <h3 className="h3 text-main-400">
              Cum se stabilește diagnosticul{" "}
            </h3>
            <p>
              Diagnosticul se stabilește pe baza anamnezei (istoricul bolii),
              examenului clinic și rezultatului investigațiilor efectuate.
            </p>
          </div>
        </div>
      </div>
      <div className="container pt-20 mx-auto text-center">
        <div className="flex-row justify-between lg:space-y-0 lg:space-x-3 lg:flex">
          <div className="w-full lg:w-6/12 bg-[#b701aa]  text-white p-4 lg:p-[90px] text-left">
            <h4 className="">
              În cazul unei suspiciuni de cancer bronho-pulmonar, pot fi
              recomandate următoarele investigații:
            </h4>
            <ul className="text-base italic font-thin lg:text-xl 2xl:text-2xl">
              <li>• Examene de laborator</li>
              <li>• Investigații imagistice: </li>
              <ul className="pl-4">
                <li>o Radiografie toracică </li>
                <li>o Tomografie computerizată (CT)</li>
              </ul>
              <li>• Bronhoscopie cu recoltare de probe tisulare (biopsie)</li>
              <li>• Teste de genetică moleculară</li>
            </ul>
          </div>
          <div className="w-full lg:w-6/12 bg-[#a008cf] text-white p-4 lg:p-[90px] text-left">
            <h4>
              Dacă rezultatele investigațiilor confirmă suspiciunea de cancer
              bronho-pulmonar, medicul va continua să identifice următoarele
              aspecte:
            </h4>
            <ul className="text-base italic font-thin lg:text-xl 2xl:text-2xl">
              <li>• Ce subtip de cancer pulmonar este prezent? </li>
              <li>• Unde este localizată tumora? </li>
              <li>• Tumora a depășit deja plămânul? </li>
              <li>• Tumora prezintă metastaze în alte organe? </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full px-4 lg:px-0">
        <div className="container pt-20 mx-auto text-center">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <p>
              Pe baza acestor criterii și a altora se determină cât mai exact
              posibil stadiul tumorii și pot fi făcute primele estimări privind
              evoluția bolii<sup>1</sup>.
            </p>

            <h3 className="h3 text-main-400">
              De ce e esențial să cunoaștem stadiul cancerului{" "}
            </h3>
            <p className="text-left">
              Să știm în ce stadiu este cancerul, înseamnă să știm de fapt cât
              de mult s-a extins acesta și care va fi cursul tratamentului .
              Pentru a putea determina stadiul bolii, se analizează dimensiunea
              și extinderea tumorii, eventuala prezență a metastazelor în
              ganglionii limfatici sau în alte organe1. Se folosește sistemul
              TNM1, un sistem de clasificare unde:
              <br />
              <br />
              <strong>
                T = tumora: dimensiunea și extinderea tumorii în plămân;
                <br />
                N = ganglioni limfatici: numărul și localizarea ganglionilor
                limfatici afectați;
                <br />M = metastaze: absența sau prezența de metastaze în alte
                organe (metastaze la distanță).
              </strong>
              <br />
              <br />
              În sistemul TNM, literele sunt însoțite de cifre și, cu cât cifra
              este mai mare, cu atât mai avansată este afectarea tumorală.
              <br />
              Sistemul TNM îi permite medicului curant să stabilească o decizie
              terapeutică individualizată, adecvată stadiului cancerului
              bronho-pulmonar și să estimeze evoluția pacientului .
            </p>
          </div>
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <p className="pt-8 text-sm text-left">
              <sup>1</sup>Bristol-Myers Squibb. (n.d). Cancerul Pulmonar:
              Diagnostic și Tratament, Un Ghid Pentru Pacienți și Aparținători;
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default CeSpuneMedicul;
