import Logos from "./Logos";

const Footer = () => {
  return (
    <div className="w-full pt-[60px]">
      <div className="w-10/12 mx-auto">
        <div className="line-logo">
          <div className="box-logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="pt-6 space-y-3 text-center ">
          <p>
            Pentru mai multe detalii va rugam sa nu ezitati sa ne contactati la
            adresa de e-mail Contact@ascultatusea.ro
            <br />@ 2022 Asculta tusea. Toate drepturile rezevate
          </p>
          <Logos />
        </div>
      </div>
    </div>
  );
};
export default Footer;
