import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const CeSpuneTusea = () => {
  return (
    <div className="w-full ">
      <div className="relative image-container mt-[0px!important]">
        <div className="absolute hidden -translate-x-1/2 lg:block left-1/2 bottom-5">
          <Icon
            icon="iconoir:mouse-scroll-wheel"
            className="text-4xl text-main-400"
          />
        </div>
        <img
          src="/images/ce-spune-tusea.png"
          alt="Fumatul"
          className="hidden lg:block"
        />
        <img
          src="/images/ce-spune-tusea-mobile.png"
          alt="Fumatul"
          className="block lg:hidden"
        />
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container pt-20 mx-auto text-center">
          <div className="mx-auto space-y-4 ">
            <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
              <h1 className="h1">
                Tusea persistentă este un simptom des întâlnit în cancerul
                bronho-pulmonar<sup>15</sup>.
              </h1>
            </div>
            <div className="w-full max-w-[900px] 2xl:max-w-[1000px] mx-auto">
              <p className="text-xl text-left ">
                Tușim pentru că am răcit, tușim pentru că avem gâtul iritat de
                fum sau praf, tușim pentru că avem o reacție alergică. Există
                multe motive care ne fac să tușim, iar cele mai multe cauze sunt
                benigne și trec. Tusea nu reprezintă automat diagnosticul de
                cancer bronho-pulmonar, dar ea este un simptom des intalnit in
                cazul acestuia. Ea este unul dintre cele mai frecvente și mai
                rapide semne pe care corpul ni le transmite când ceva nu e în
                regulă. De aceea, dacă învățăm să o ascultăm, tusea poate deveni
                un indiciu eficient pentru depistarea la timp a cancerului de
                plămân.
              </p>
              <h3 className="py-6 text-2xl font-bold text-left text-main-400 ">
                Există câteva particularități<sup>16</sup> care sunt ușor de
                urmărit și care ne spun, dacă tusea poate ascunde ceva mai mult
                decât o simplă situație trecătoare:
              </h3>
              <ul className="pt-6 text-left">
                <li>
                  • este cronică - durează mai mult de 3 săptămâni consecutive,
                  nu se ameliorează sau se agravează
                </li>
                <li>• poate afecta somnul sau calitatea vieții</li>
                <li>
                  • este însoțită de expectorații cu sânge (chiar și în
                  cantități foarte mici)
                </li>
                <li>
                  • este însoțită de dureri în piept sau dificultăți de
                  respirație
                </li>
                <li>• se schimbă în intensitate</li>
              </ul>
            </div>
            <h1 className="h1">Să prevenim cancerul bronho-pulmonar!</h1>
            <h3 className="h3 text-main-400 max-w-[800px] mx-auto">
              Cancerul de plămân este una dintre cauzele principale de deces în
              România și care poate fi prevenită<sup>17</sup>.
            </h3>
          </div>
        </div>
      </div>
      <div className="container pt-20 mx-auto text-center">
        <div className="w-full mx-auto">
          <div className="flex-row justify-between lg:space-y-0 lg:space-x-3 lg:flex">
            <div className="w-full lg:w-4/12 bg-[#b701aa]  text-white p-4 lg:p-[40px] text-center">
              <h4>Renunțați la fumat!</h4>
              <p className="text-base 2xl:text-xl">
                Este factorul principal de risc și sta în puterea fiecăruia
                dintre noi să-l eliminăm, pentru a preveni apariția cancerului
                bronho-pulmonar. Chiar dacă fumați de mult, din momentul în care
                renunțați, riscul apariției cancerului scade cu fiecare an
                <sup>18</sup>. Vorbiți cu medicul despre opțiunile privind
                tratamentul sindromului de sevraj, faceți împreună un plan de
                renunțare la fumat, apelați la grupuri de suport.{" "}
              </p>
            </div>
            <div className="w-full lg:w-4/12 bg-[#a008cf] text-white p-4 lg:p-[40px] text-center">
              <h4>Evitați fumatul pasiv. </h4>
              <p className="text-base 2xl:text-xl">
                Expunerea ambientală la fumul de tutun crește riscul apariției
                cancerului bronho-pulmonar, chiar dacă riscul este mai mic decât
                în cazul fumătorilor activi<sup>4</sup>. Evitați spațiile în
                care se fumează, iar acasă, dacă cineva din familie fumează,
                stabiliți un spațiu în aer liber în care să se fumeze (un balcon
                deschis de exemplu).
              </p>
            </div>
            <div className="w-full lg:w-4/12 bg-[#6408c4] text-white p-4 lg:p-[40px] text-center">
              <h4> Adoptați un stil de viață sănătos.</h4>
              <p className="text-base 2xltext-xl">
                Cercetările sugerează că riscul apariției diverselor tipuri de
                cancer, inclusiv al celui bronho-pulmonar, , scade dacă avem
                grijă la cei 2 „M”: mâncare și mișcare. Aceasta înseamnă să avem
                o dietă bazată pe fructe și legume proaspete și cereale
                integrale18, care să ne asigure vitaminele și nutrienții de care
                organismul nostru are nevoie și să facem mișcare, pe cât
                posibil, în fiecare zi.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container mx-auto pt-11">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <p className="text-xs lg:text-sm">
              <sup>4</sup>European Society for Medical Oncology. (2019).
              Cancerul de Plămân Fără Celule Mici: Ghidul pentru Pacienți
              [Online] Disponibil la
              https://www.esmo.org/content/download/303704/6040931/1/RO-Cancerul-de-Plaman-Fara-Celule-Mici-Ghidul-pentru-Pacienti.pdf
              [Accesat la 18.07.2022]
              <br />
              <sup>15</sup>Nicole Galan, 2022. What is the link between lung
              cancer and a cough? Medica News Today. Medically reviewed by Fred
              Aleskerov, MD. Available at:
              https://www.medicalnewstoday.com/articles/319449[Accesat la
              08.08.2022]
              <br />
              <sup>16</sup>The American Cancer Society. Signs and Symptoms of
              Lung Cancer. 2019. Disponibil la:
              https://www.cancer.org/cancer/lung-cancer/detection-diagnosis-staging/signs-symptoms.html[Accesat
              la 08.08.2022]
              <br />
              <sup>17</sup>OECD/European Observatory on Health Systems and
              Policies (2021), Romania: Country Health Profile 2021, State of
              Health in the EU, OECD Publishing, Paris/European Observatory on
              Health Systems and Policies, Brussels.
              <br />
              <sup>18</sup> PDQ® Screening and Prevention Editorial Board. PDQ
              Lung Cancer Prevention. Bethesda, MD: National Cancer Institute.
              Updated 04.08.3021.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CeSpuneTusea;
