const Logos = () => (
  <>
    <p>O campanie de conștientizare privind prevenția cancerului pulmonar</p>
    <div className="lg:flex items-center w-full max-w-[750px] mx-auto space-x-3">
      <div className="w-full text-left lg:w-2/3">
        <p className="pb-4 text-sm text-center uppercase lg:text-left">
          inițiată de:
        </p>
        <div className="flex items-center space-x-4">
          <div className="box-logo-footer">
            <img src="/images/l1.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l2.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l3.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l4.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l5.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l6.png" alt="l1" />
          </div>
          <div className="box-logo-footer">
            <img src="/images/l7.png" alt="l1" />
          </div>
        </div>
      </div>
      <div className="w-full text-left lg:w-1/3">
        <p className="pb-4 text-sm text-center uppercase lg:text-left">
          Susținută de:
        </p>
        <div className="flex items-end box-logo-footer">
          <img src="/images/l1.svg" alt="l1" />
        </div>
      </div>
    </div>
  </>
);

export default Logos;
