import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const FatiUnPlan = () => {
  return (
    <div className="w-full ">
      <div className="relative image-container  w-full text-white lg:pt-0 mt-[0px!important]">
        <div className="absolute hidden -translate-x-1/2 lg:block left-1/2 bottom-5">
          <Icon
            icon="iconoir:mouse-scroll-wheel"
            className="text-4xl text-main-400"
          />
        </div>
        <img
          src="/images/fatiunplan.png"
          className="hidden lg:table-cell"
          alt="Fumatul"
        />
        <img
          src="/images/fatiunplan-mobile.png"
          className="block lg:hidden"
          alt="Fumatul"
        />

        <div className="left-0 w-full text-center lg:absolute lg:top-16">
          <h1 className="w-full text-main-400 lg:text-white h1-white">
            Un diagnostic nu este o condamnare
          </h1>
        </div>
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container mx-auto text-left pt-7 lg:pt-20">
          <div className="w-full lg:w-11/12 max-w-[1090px] mx-auto lg:flex space-y-7 lg:space-y-0 lg:space-x-7 ">
            <div className="w-full lg:w-6/12">
              <h3 className="h3 text-main-400">Planul oncologic</h3>
              <p className="text-base ">
                În cazul diagnosticului de cancer bronho-pulmonar, tipul,
                localizarea și stadiul tumorii sunt informații decisive in
                alegerea planului de tratament<small>4</small>. Diagnosticul de
                cancer bronho-pulmonar nu reprezinta o condamnare, cu atât mai
                mult atunci când boala este diagnosticata la timp.
                <br />
                <br />
                În situațiile în care cancerul bronho-pulmonar este diagnosticat
                în stadiile incipiente I și II, fără extindere evidențiată,
                prognosticul este unul optimist. Adesea, se încearcă
                îndepărtarea completă a tumorii prin operație, deoarece aceasta
                oferă cea mai mare șansă de vindecare completă. În cazul în care
                cancerul bronho-pulmonar este diagnosticat attunci când deja s-a
                extins (stadiile avansate III și IV), prognosticul pentru o
                vindecarea completă este rezervat<small>1,3</small>.
                <br />
                <br />
                Medicul se consultă cu echipa multidisciplinară și îi explică
                pacientului posibilele opțiuni de tratament. Împreună decid
                asupra terapiei, ținând cont de specificitatea fiecărui caz:
                <br />
              </p>
              <ul>
                <li>• intervenție chirurgicală</li>
                <li>• radioterapie</li>
                <li>• chimioterapie</li>
                <li>• terapii țintite</li>
                <li>• terapie imuno-oncologică</li>
              </ul>
              <p>
                Opțiunea terapeutică depinde și de istoricul pacientului.
                Astfel, sunt evaluate: vârsta, starea generală de sănătate,
                antecedentele medicale, istoricul de fumător, rezultatele
                analizelor de sânge și investigațiilor imagistice.
              </p>
            </div>
            <div className="w-full lg:w-6/12">
              <h3 className="h3 text-main-400">Echipa multidisciplinară</h3>
              <p className="text-xl italic font-thin ">
                În majoritatea spitalelor, o echipă multidisciplinara va
                planifica tratamentul adecvat pentru fiecare pacient. De regulă,
                o asemenea echipă reunește diverși specialiști<sup>4</sup>:
              </p>
              <ul className="text-xl italic font-thin ">
                <li>• chirurg</li>
                <li>
                  • oncolog - specializat în abordarea din perspectiva medicală
                  a cancerului
                </li>
                <li>• oncolog radiolog (radioterapeut)</li>
                <li>• pneumolog</li>
                <li>• asistentă specializată</li>
                <li>
                  • radiolog - implicat în evaluarea radiografiilor și
                  investigațiilor imagistice
                </li>
                <li>
                  • anatomopatolog - implicat în analiza biopsiei din tumoră
                </li>
                <li>
                  • biolog molecular - implicat în analiza genetică a biopsiei
                  din tumoră
                </li>
                <li>
                  • psiho-oncolog - specializat în evaluare psihologică și
                  consiliere
                </li>
              </ul>

              <p className="text-xl italic font-thin ">
                Există și alți specialiști care pot fi implicați: nutriționist,
                asistent social, asistentă comunitară, fizioterapeut, psiholog
                clinician și specialist în îngrijirea paliativă (cel care poate
                ajuta în prevenirea și înlăturarea suferinței, prin
                identificarea precoce, evaluarea și tratamentul durerii și al
                altor probleme fizice și psiho-sociale).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full my-8 bg-main-500">
        <div className="container py-8 mx-auto space-y-3 text-center text-white ">
          <h3 className="h3">Planul emoțional</h3>
          <p className="text-xl italic font-thin">
            Diagnosticul de cancer bronho-pulmonar nu are consecințe numai la
            nivelul corpului. Pentru pacient, dar și pentru aparținători, poate
            fi o povară sufletească. Pacienții diagnosticați cu cancer simt
            nevoia și de sprijin emoțional în gestionarea bolii și pot beneficia
            de consiliere psiho-oncologică, care este oferită de numeroase
            asociații de pacienți. Măsurile psiho-oncologice sprijină persoanele
            afectate și pe aparținătorii acestora în abordarea emoțională a
            afecțiunii tumorale și în gestionarea situației de viață schimbate,
            care a apărut ca urmare a diagnosticului1.
          </p>
        </div>
      </div>
      <div className="container pt-8 mx-auto text-left lg:pt-20">
        <div className="w-11/12 max-w-[1090px] mx-auto flex space-x-7 ">
          <div className="w-6t/12">
            <h3 className="h3 text-main-400">Viața după tratament</h3>
            <p className="text-base ">
              <br />
              Sfârșitul tratamentului nu înseamnă sfârșitul îngrijirii. Cancerul
              trebuie constant monitorizat, iar o bună gestionare a vindecării,
              presupune vizite regulate la medic pentru consiliere individuală
              și controlul evoluției bolii. După încheierea unui tratament,
              pentru fiecare pacient se creează un plan individual de îngrijire
              ulterioară, ajustat în funcție de stadiul bolii și de tratamentul
              urmat<sup>1</sup>.
              <br />
              <br />
              Planul de îngrijire ulterioară are trei obiective1 esențiale:
            </p>
            <ul>
              <li>
                • să recunoască și să trateze la timp o eventuală recidivă a
                cancerului{" "}
              </li>
              <li>
                • să identifice și să trateze afecțiunile asociate cancerului
              </li>
              <li>
                • să ofere sprijin pacienților pentru problemele lor emotionale,
                fizice și sociale.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container mx-auto pt-11 max-w-[1200px]">
          <table
            className="tableinfo"
            cellPadding={2}
            cellSpacing={0}
            border={1}
          >
            <tr>
              <td colSpan={2}>
                <h3 className="h3">Schema îngrijirii ulterioare</h3>
              </td>
            </tr>
            <tr>
              <td className="hidden lg:table-cell">
                <p>
                  4 - 6 săptămâni după <br />
                  încheierea tratamentului
                </p>
              </td>

              <td>
                <p className="block text-2xl font-bold lg:hidden ">
                  4 - 6 săptămâni după <br />
                  încheierea tratamentului
                </p>
                <p>
                  Examen clinic, hemoleucogramă, examinări ale funcției
                  pulmonare, radiografie pulmonară. În cazul tratamentului
                  medicamentos: controlul responsivităţii, al efectelor
                  secundare și al acuzelor.
                </p>
              </td>
            </tr>
            <tr>
              <td className="hidden lg:table-cell">
                <p>
                  12 săptămâni după <br />
                  încheierea tratamentului
                </p>
              </td>

              <td>
                <p className="block text-2xl font-bold lg:hidden ">
                  12 săptămâni după <br />
                  încheierea tratamentului
                </p>

                <p>
                  Examen clinic, hemoleucogramă, examinări ale funcției
                  pulmonare, radiografie pulmonară.
                  <br />
                  Alte proceduri imagistice (de ex. CT sau RMN) pot fi necesare
                  în cazul unui risc crescut de recidivă sau de progresie a
                  bolii.
                  <br />
                  În cazul tratamentului medicamentos: controlul
                  responsivităţii, al efectelor secundare și al acuzelor.
                </p>
              </td>
            </tr>
            <tr>
              <td className="hidden lg:table-cell">
                <p>
                  Luna 3 până la finalul <br />
                  celui de-al 2-lea an
                </p>
              </td>

              <td>
                <p className="block text-2xl font-bold lg:hidden ">
                  Luna 3 până la finalul <br />
                  celui de-al 2-lea an
                </p>
                <p>
                  Control regulat la fiecare trei luni. <br />O dată pe an
                  tomografie computerizată toracică (CT toracic). <br />
                  Alte examinări imagistice (de ex. ecografia abdominală,
                  radiografie, CT/ RMN sau bronhoscopie) pot fi efectuate
                  suplimentar în cazul suspiciunii unei recidive sau în cazul
                  apariției de metastaze. <br />
                  În caz de progresie a bolii: dacă este posibil,
                  ajustarea/schimbarea tratamentului medicamentos.
                </p>
              </td>
            </tr>
            <tr>
              <td className="hidden lg:table-cell">
                <p>Anii 3 până la 5</p>
              </td>

              <td>
                <p className="block text-2xl font-bold lg:hidden ">
                  Anii 3 până la 5
                </p>
                <p>
                  Controale regulate la fiecare șase luni și CT toracic o dată
                  pe an
                  <br />
                  Examinări imagistice în funcție de tabloul simptomatic al
                  pacientului.
                </p>
              </td>
            </tr>
            <tr>
              <td className="hidden lg:table-cell">
                <p>Din al 6-lea an</p>
              </td>

              <td>
                <p className="block text-2xl font-bold lg:hidden ">
                  Din al 6-lea an
                </p>
                <p>O examinare de control pe an.</p>
              </td>
            </tr>
          </table>
        </div>
        <div className="container mx-auto pt-11">
          <p className="text-xs lg:text-sm">
            <sup>1</sup>Bristol-Myers Squibb. (n.d). Cancerul Pulmonar:
            Diagnostic și Tratament, Un Ghid Pentru Pacienți și Aparținători;
            <br />
            <sup>3</sup>Goeckenjan G. et al. Prävention, Diagnostik, Therapie
            und Nachsorge des Lungenkarzinoms Interdisziplinäre S3-Leitlinie der
            Deutschen Gesellschaft für Pneumologie und Beatmungsmedizin und der
            Deutschen Krebsgesellschaft.
            <br />
            <sup>4</sup>European Society for Medical Oncology. (2019). Cancerul
            de Plămân Fără Celule Mici: Ghidul pentru Pacienți [Online]
            Disponibil la
            https://www.esmo.org/content/download/303704/6040931/1/RO-Cancerul-de-Plaman-Fara-Celule-Mici-Ghidul-pentru-Pacienti.pdf
            [Accesat la 18.07.2022]
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FatiUnPlan;
