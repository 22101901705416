import { useRef, useState } from "react";
import Logos from "../components/Logos";
const Home = () => {
  const [hasChecked, setHasChecked] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  useRef(() => {
    videoRef.current.play();
  }, []);
  if (hideVideo) {
    return (
      <div className="min-h-screen overflow-x-hidden">
        <div className="fixed top-0 left-0 z-10 w-full h-screen">
          <img src="/images/kv.png" className="VideoTag" />
          <div className="bg-main-500 absolute right-0 flex items-center bottom-0 rounded-tl-[55px] w-[380px] 3xl:w-[490px] top-auto z-20 ">
            <div className="flex items-center">
              <div className="text-[25px] 3xl:text-[28px] text-white px-6 py-8 pr-16 text-center  2xl:h-[500px]">
                <strong>
                  Alături de respirație șuierătoare, dificilă și dureri în
                  piept, tusea poate ascunde cancer pulmonar.
                </strong>
                <br />
                Întreabă-ți medicul de familie despre opțiunile de evaluare
                timpurie.
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:absolute w-9/12 bottom-0 right-0 pr-[390px] 3l:pr-[490px] pl-10 pt-2 z-10 bg-white rounded-tl-[55px]">
            <Logos />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-screen overflow-x-hidden">
      <div className="fixed top-0 left-0 w-full h-screen">
        <video
          className="VideoTag"
          autoPlay
          onClick={() => {
            videoRef.current.play();
            setIsPlaying(true);
          }}
          onCanPlay={() => {
            videoRef.current.play();
          }}
          loop
          ref={videoRef}
          onEnded={() => {
            console.log("hasChecked", hasChecked);
            if (hasChecked) {
              videoRef.current.currentTime = 7.9;
            }
          }}
          onTimeUpdate={(e) => {
            setIsPlaying(true);
            if (e.target.currentTime > 7.8 && !hasChecked) {
              e.target.currentTime = 0;
            }
            if (e.target.currentTime > 15.7 && hasChecked) {
              videoRef.current.currentTime = 7.9;
            }
          }}
        >
          <source
            src={window.innerWidth > 1024 ? "/videos.mp4" : "/video-mobile.mp4"}
            type="video/mp4"
          />
        </video>
        {!isPlaying && (
          <div
            onClick={() => {
              videoRef.current.play();
            }}
            className="bg-main-500 w-[197px] uppercase text-center h-[70px] text-white cursor-pointer  justify-center text-[30px] leading-none flex font-bold items-center rounded-[45px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
          >
            start
          </div>
        )}
        <div className="fixed bottom-0 flex right-0 bg-[#b701ab] px-6 justify-between py-5 rounded-tl-[30px] w-full lg:w-[550px] text-white">
          <img src="/images/soundbar.png" alt="soundbar" />
          {hasChecked ? (
            <div className="text-left lg:w-[400px] lg:flex items-center">
              <span
                onClick={() => {
                  setHideVideo(true);
                }}
                className="inline-block px-2 py-1 mr-1 bg-white rounded-lg cursor-pointer text-main-500"
              >
                Oprește tusea
              </span>{" "}
              pentru a afla mai multe
            </div>
          ) : (
            <div className="text-left">
              Zgomotul de zi cu zi bruiază ce e cu adevărat important.
              <br />
              <span
                onClick={() => {
                  setHasChecked(true);
                  videoRef.current.currentTime = 7.9;
                }}
                className="inline-flex content-center justify-center px-2 py-1 text-center bg-white rounded-lg cursor-pointer text-main-500"
              >
                Oprește zgomotul
              </span>{" "}
              să asculți semnele!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Home;
