import Footer from "../components/Footer";

import { Icon } from "@iconify/react";

const CeSpunSemnele = () => {
  return (
    <div className="w-full container-align-t">
      <div className="container mx-auto text-center">
        <div className="container mx-auto text-center">
          <h1 className="text-center h1">
            Până la 90% din totalul tumorilor pulmonare maligne sunt cauzate de
            fumat.<sup>1,3</sup>
          </h1>
        </div>
      </div>
      <div className="relative image-container">
        <div className="absolute hidden -translate-x-1/2 -translate-y-1/2 lg:block left-1/2 top-1/2">
          <Icon
            icon="iconoir:mouse-scroll-wheel"
            className="text-4xl text-main-400"
          />
        </div>

        <img
          src="/images/semnale-1.jpg"
          alt="Fumatul"
          className="hidden w-7/12 lg:block"
        />
        <img
          src="/images/semnale-1-mobile.png"
          alt="Fumatul"
          className="block w-full lg:hidden"
        />

        <div className="container relative bottom-0 mx-auto text-center lg:bottom-0 lg:-translate-x-1/2 lg:absolute lg:left-1/2">
          <div className="bg-main-400 box-rounded-1 w-11/12 lg:w-[500px] text-white">
            <div className="w-10/12 py-10 mx-auto space-y-3">
              <h3 className="h3">Fumatul</h3>
              <p className="text-base 2xl:text-[18px]">
                În Europa, 90% dintre cazurile de cancer diagnosticate la
                bărbați și 80% dintre cele apărute la femei sunt cancere
                pulmonare. Principala cauză a cancerului de plămâni este
                fumatul. Să renunți la fumat, indiferent de vârstă, nu doar să
                reduci numărul de țigări fumate pe zi, scade mult riscul
                apariției cancerului, pentru că studiile arată că durata
                fumatului este mai importantă decât numărul de țigări fumate
                într-o zi.<sup>4</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container pt-20 mx-auto lg:flex">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto items-start justify-between flex ">
            <div className="w-full mx-auto space-y-3 lg:w-5/12">
              <div>
                <h3 className="h3">Fumatul pasiv</h3>
                <p>
                  Adică expunerea ambientală la fumul de tutun<sup>5</sup>.
                </p>
              </div>
              <div>
                <h3 className="h3">Poluarea aerului</h3>
                <p>
                  Traficul intens, praful fin, precum și alte surse de poluare
                  în orașe<sup>6,7</sup>.
                </p>
              </div>
              <div>
                <h3 className="h3">
                  Contactul cu anumite minerale, substanțe periculoase sau
                  radiații ionizante
                </h3>

                <p>
                  De exemplu: azbest<sup>8</sup>, arsenic, nichel, crom, cadmiu
                  <sup>9</sup>, radon, Röntgen<sup>10</sup> și altele.
                </p>
              </div>
            </div>
            <div className="w-[1px] hidden lg:block h-full bg-[#2b292b] min-h-[250px] "></div>
            <div className="w-full pt-8 mx-auto space-y-3 lg:pt-0 lg:w-5/12">
              <div>
                <h3 className="h3">Anumite afecțiuni virale</h3>
                <p>
                  Anumite afecțiuni virale se evidențiază tot mai mult rolul
                  unor virusuri (precum infecția cu virusul Papiloma uman, HPV -
                  Human Papiloma Virus) în apariția cancerului bronho-pulmonar
                  <sup>11, 12, 13</sup>.
                </p>
              </div>
              <div>
                <h3 className="h3">Factori genetici</h3>
                <p>
                  Moștenirea genetică (istoricul familial) poate crește riscul
                  apariției cancerului bronho-pulmonar.<sup>14</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto pt-11">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <p className="text-xs lg:text-sm">
              <sup>1</sup>Bristol-Myers Squibb. (n.d). Cancerul Pulmonar:
              Diagnostic și Tratament, Un Ghid Pentru Pacienți și Aparținători;
              <br />
              <sup>3</sup>Goeckenjan G. et al. Prävention, Diagnostik, Therapie
              und Nachsorge des Lungenkarzinoms Interdisziplinäre S3-Leitlinie
              der Deutschen Gesellschaft für Pneumologie und Beatmungsmedizin
              und der Deutschen Krebsgesellschaft.
              <br />
              <sup>4</sup>European Society for Medical Oncology. (2019).
              Cancerul de Plămân Fără Celule Mici: Ghidul pentru Pacienți
              [Online] Disponibil la
              https://www.esmo.org/content/download/303704/6040931/1/RO-Cancerul-de-Plaman-Fara-Celule-Mici-Ghidul-pentru-Pacienti.pdf
              [Accesat la 18.07.2022]
              <br />
              <sup>5</sup>International Agency for Research on Cancer (IARC).
              Monographs on the Evaluation of Carcinogenic Risks to Humans,
              Volume 83. Tobacco Smoke and Involuntary Smoking. Lyon: World
              Health Organization, 2004.
              <br />
              <sup>6</sup>WHO. Air Quality Guidelines Global Update 2005. WHO
              Copenhagen 2006.
              <br />
              <sup>7</sup>US-EPA Environmental Protection Agency. Air Quality
              Criteria for Particulate Matter. EPA/600/P-99/D02bF. Washington:
              2004.
              <br />
              <sup>8</sup>International Agency for Research on Cancer (IARC).
              Monographs on the Evaluation of Carcinogenic Risks to Humans,
              Volume 100C. Arsenic, Metals, Fibres and Dusts. Lyon: World Health
              Organization, 2012.
              <br />
              <sup>9</sup>Butz M. Beruflich verursachte Krebserkrankungen: Eine
              Darstellung der im Zeitraum 1978-2010 anerkannten
              Berufskrankheiten. 10., überarbeitete und ergänzte Auflage, 2012.
              Verfügbar unter: http://
              publikationen.dguv.de/dguv/pdf/10002/berufl_krebs- 07-14-06.pdf.
              Abgerufen am 15. Juli 2015.
              <br />
              <sup>10</sup>SSK Strahlenschutzkommission. Band 66: Risiken
              ionisierender und nichtionisierender Strahlung. Veröffentlichungen
              der SSK, Bonn, 2011. ISBN 978-3-87344-168-2.
              <br />
              <sup>11</sup>Giuliani L et al. Human papillomavirus infections in
              lung cancer. Detection of E6 and E7 transcripts and review of the
              literature. Anticancer Res 2007; 27: 2697-2704.
              <br />
              <sup>12</sup>Ho JC,Wong MP, Lam WK. Lymphoepithelioma-like
              carcinoma of the lung. Respirology 2006; 11: 539-545.
              <br />
              <sup>13</sup>Subramanian J, Govindan R. Lung cancer in never
              smokers: a review. J Clin Oncol 2007; 25: 561-570.
              <br />
              <sup>14</sup>Schwartz AG, Yang P, Swanson GM. Familial risk of
              lung cancer among nonsmokers and their relatives. Am J Epidemiol
              1996; 144: 554-562.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default CeSpunSemnele;
